// SS - our version of cards
// copied from admin custom/_dashboard.scss
$block-bg: white;
$block-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-border-radius: 5px;
$block-border: none;
$block-spacing: 1.25rem 1.25rem; //20px 20px;
$block-hover-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
$block-margin-bottom: 24px;
$block-transition: all 0.4s ease-in-out 0s;

.card {
  background-color: $block-bg;
  box-shadow: $block-shadow;
  border: $block-border;
  border-radius: $block-border-radius;
  margin: 0.125rem 0.125rem $block-margin-bottom 0.125rem;
  transition: $block-transition;
  padding: $block-spacing;
  position: relative;
  min-height: 190px;

  &:hover {
    box-shadow: $block-hover-shadow;
  }
  &.fixed-auth {
    padding: 30px;
    min-height: 500px;
    justify-content: center;
    margin: 0;
    width: 80%;
    @media (min-width: 768px) {
      width: 550px;
      padding: 45px;
    }
  }
  &.fixed-sm {
    width: 400px;
    min-height: 250px;
  }
}

.auth-banner {
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  color: black;
}

.banner-fixed-desktop {
  @media (min-width: 768px) {
    width: 750px;
    margin-top: 4rem;
  }
}

.link-hover {
  text-decoration: underline;
  &.muted {
    // same as text-muted, but without !important
    color: #6c757d;
  }
  &:hover {
    color: #f3786c;
  }
}

// Also using this in self serve wizard
.card-title {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1.5rem; //20px;
  font-weight: 500;
  text-align: center;

  &.bolder {
    color: #373737;
    font-weight: 600;
    font-size: 1.8rem;
  }
}

input[disabled] ~ .character {
  cursor: not-allowed;
  opacity: 0.5;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  max-width: 350px !important;

  &.vi {
    :disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.character {
  border: none;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #373737;


}

.character--filled {
  color: #f3786c;
  border: none;
}

.character--inactive {
  background-color: transparent;
  color: #373737;
}

.character--selected {
  position: relative;
  border: none;
  outline: none;
  color: #373737;
}



/* optionally hide placeholder (in case it overlaps the cursor) */
.character--selected:not(.character--filled) {
  color: transparent;
}

.character--selected:not(.character--filled)::after {
  content: "";
  width: 2px;
  height: 2rem;
  background-color: #373737;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: blink 1s infinite step-end;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

